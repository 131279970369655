<template>
    <div class="common_content social_box">
        <div class="social_top_box">
            <div class="social_title  animated fadeInDown">
                社会公益 <br/><span>/  SOCIAL WELFARE</span>
            </div>
            <div class="social_title_right  animated bounceInRight">
                热衷公益、奉献社会
            </div>
        </div>
        <div class="social_text animated fadeInDown">
            一直以来，琪尔特秉承“热衷公益、奉献社会”的使命和愿景，热心社会公益事业和慈善事业，先后为泉州市丰泽教育发展基金会、北京富德公益基金会及陕北榆林、子洲地区的学校捐赠了大量教育资金和运动装备，以实际行动支持国家教育事业发展建设，并赞助了多届“琪尔特杯”海峡两岸大学生设计工作坊大奖赛，促进了海峡两岸的和谐交流。
<p>此外，琪尔特心系海外同胞，在2019年春节期间前往黎巴嫩慰问中国驻黎巴嫩维和部队，带去了600套为官兵们量身定做的服装，以实际行动为春节期间守护世界和平的中国驻黎巴嫩维和部队官兵，送去来自祖国真挚的问候与祝福，在向肩负和平使命的官兵们致敬。</p>
        </div>
        <div class="social_img animated bounceInRight">
            <img src="../../assets/news/social_image.png"/>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.common_content{
    margin-left: 312px;
    margin-top: 4vh;
}
.social_box{
    margin-right: 108px;
}
.social_top_box{
position: relative;
}
.social_title{
font-size: 46px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #000000;
}
.social_title span{
    font-size: 25px;
    color: #EE660E;
}
.social_title_right{
font-size: 25px;
font-family: AlibabaPuHuiTiR;
font-weight: bolder;
color: #EE660E;
    position: absolute;
    top: 86px;
    right: 0;
}
.social_text{
font-size: 16px;
font-family: AlibabaPuHuiTiL;
/* font-weight: bold; */
color: #000000;
line-height: 33px;
margin-top: 70px;
margin-bottom: 52px;
}
.social_img{
    
}
.social_img img{
    width: 100%;
}
</style>